.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.winner-dialog {
    background: linear-gradient(145deg, #1a1a1a, #2a2a2a);
    border-radius: 20px;
    padding: 2rem;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
    transform: scale(0.9);
    opacity: 0;
    transition: all 0.3s ease-out;
}

.winner-dialog.animate {
    transform: scale(1);
    opacity: 1;
}

.winner-dialog.winner {
    border: 3px solid #ffd700;
}

.winner-dialog.loser {
    border: 3px solid #ff4444;
    background: linear-gradient(145deg, #1a1a1a, #2a1a1a);
}

.winner-dialog.loser .dialog-title {
    color: #ff6666;
}

.winner-dialog.loser .dialog-button {
    background: linear-gradient(145deg, #2a1a1a, #3a1a1a);
}

.winner-dialog.loser .dialog-button:hover {
    background: linear-gradient(145deg, #3a1a1a, #4a1a1a);
    box-shadow: 0 5px 15px rgba(255, 68, 68, 0.2);
}

.dialog-content {
    text-align: center;
    min-width: 300px;
}

.dialog-title {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #ffffff;
    font-weight: bold;
}

.dialog-subtitle {
    font-size: 1.1rem;
    color: #b8b8b8;
    margin-bottom: 2rem;
}

.dialog-decoration {
    margin: 2rem 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trophy-animation {
    font-size: 4rem;
    animation: bounce 1s infinite;
}

.star-animation {
    font-size: 4rem;
    animation: rotate 2s infinite;
}

.dialog-button {
    background: linear-gradient(145deg, #2a2a2a, #3a3a3a);
    border: none;
    color: white;
    padding: 12px 30px;
    border-radius: 25px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.dialog-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    background: linear-gradient(145deg, #3a3a3a, #4a4a4a);
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
}

@keyframes rotate {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .dialog-title {
        font-size: 1.5rem;
    }
    
    .dialog-subtitle {
        font-size: 1rem;
    }
    
    .trophy-animation,
    .star-animation {
        font-size: 3rem;
    }
} 