
.overlay-header {
    position: fixed;
    bottom: 4px;
    right: 4px;
    /* position:sticky; */
    z-index: 9999;
    display: flex;
    justify-content: flex-end;
    /* align-items: center; */
    /* color: white; */
  }