*{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-image: url("../public/bg-main.png");
  background-repeat: no-repeat;
  background-size: cover; */
}

/* #root {
  color: white;
} */

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.row-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.row-container-end {
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: row;
}

.dark-bg{
  background-color: black;
}

.dark-txt{
  color : black;
}

.spikes-up { 
  background-image: url("../public/black_triangle.png");
  background-repeat: repeat-x;
  background-size: auto;
  height: 35px;
  transform: rotate(180deg);
}

.spikes-down { 
  background-image: url("../public/black_triangle.png");
  background-repeat: repeat-x;
  background-size: auto;
  height: 35px;
}

.italic{
  font-style: italic;
}

.hoverBtn {
  transition: transform 0.3s ease;
  cursor: pointer;
}

.hoverBtn:hover{
  transform: scale(0.9)
}

.opacity-c {
  opacity: 65%;
}

.c214 {
  color: rgb(214, 214, 214);
}

.mainBtn{
  background-color: #0078ee;
  border: 2px solid #fff;
  box-shadow: inset 0 -5px 20px rgba(0,0,0,.4),inset 0 5px 20px rgba(255,255,255,.4),-8px 8px 5px rgba(0,0,0,.15),5px 10px 10px rgba(0,0,0,.2);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0 7px;
  padding: 12px 0;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 0 15px rgba(0,0,0,.75),0 2px 4px #000;
  text-transform: uppercase;
  transition: box-shadow .15s,-webkit-transform .15s;
  transition: transform .15s,box-shadow .15s;
  transition: transform .15s,box-shadow .15s,-webkit-transform .15s;
  width: 20rem;
}

.yellowBtn{
  background-color: #ffd100;

}


/* DIALOG */
.dialog-main-bg {
  z-index: 99998;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-main {
  z-index: 99999;
  background-color: white;
  position: relative;
  max-height: 90vh;
  width: auto;
  min-width: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 5px 10px;
}

.dialog-border {
  border: 2px solid black;
  padding: 5px;
}

.no-scroll {
  overflow: hidden;
}

.dialog-close-button {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 15px;
  cursor: pointer;
}

.dialog-close-button p {
  margin-right: 5px;
  font-weight: 500;
}

/* Scrollbar styling for the dialog */
.dialog-main::-webkit-scrollbar {
  width: 8px;
}

.dialog-main::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.dialog-main::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.dialog-main::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Firefox scrollbar */
.dialog-main {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* RULES ---------------------------------------------------------------------------- */

.rules-main{

  z-index: 99999;
  /* background-color:rgb(8, 8, 8); */
  background-color:rgb(8, 8, 8);
  position: fixed;
  width:90%;
  height: 90%;
  top: 5%;
  left: 5%;

  border: 3px groove grey;

  color:white;

}

.rules-inbetween-img {
  display: inline-block;
  font-size: 5em;
  color: #fff;
  padding: 0 3rem;
  position: relative;
  top: 40%;
  /* -webkit-transform: translateY(-50%); */
  transform: translateY(-50%);
}

.rules-main-center-img-3 img{
  width : 10%;
  margin: 1%;
}

.rules-main-center-img img{
  width : 20%;
  margin: 1%;
}

.rules-main-center-img-3 span{
  font-size: 2em;
  color: #15ffa5;
}

.rules-main-btns{
  position: absolute;
  bottom:10px;
  margin: auto;
  display: flex;
  justify-content: center;
  margin:10px;
  transform: scale(2);
}

.rules-main-closebtn{
  position: absolute;
  top:10px;
  right:10px;
  transform: scale(1.25);
}

.arrow-left-svg {
  transform: rotate(180deg);
  margin-bottom: 3px;
}


/* LEADERBOARD -----------------------------------------  */

.leaderboard-bg{
  background: url('../public/dots_blue2.png') repeat-x,#0078ee;
  background-size: auto, auto;
  background-size: auto 100%;
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -2;
}

.aside-yellow-box{
  margin: 2rem auto;
  max-width: 120rem;
  overflow: hidden;
  position: relative;
  width: 95%;
  z-index: 1;
}
.aside-yellow-box-heading{
  color: #fff;
  display: block;
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.3;
  background-color: #000;
  margin: 0;
  padding: 1.5rem 0 1rem;
  /* position: relative; */
  width: 100%;
  z-index: 1;
  text-align: center;
}

.aside-yellow-box-content{
  background-color: #ffe600;
  padding: 1.5rem 5.5rem 2.5rem;
  width: 100%;
}


.leaderboard-table tbody tr td{
  width:200px;
  background-color: rgba(25, 26, 26, 0.5);
  text-align: center;
  padding: 10px 0px;
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
}

.leaderboard-table-my tbody tr td{
  background-color: rgba(190, 43, 192, 0.5);
}

.leaderboard-table tbody tr td:nth-child(2){
  width:350px;
  clip-path: polygon(6% 0, 100% 0, 94% 100%, 0 100%);

}

.leaderboard-table tbody{
  overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;

}

.td-field-overflow{
  width:50px;
  text-wrap: wrap;
}

/* WALLET -------------------------------------------------------- */
.wallet-history-div{
  border : 2px solid black;
  background-color: #ffd100;
  height: 400px;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 100px;
}
.wallet-history-div-header{
  height:50px;
  text-align: center;
  font-size: x-large;
  padding-top: 10px;
  display:flex;
  justify-content: space-evenly;
}
.wallet-history-div-content{
  background-color: black;
  width:99%;
  margin: 0 auto;
  height: 350px;
  overflow-y: scroll;
}

.wallet-history-div-content div{
  background-color: #494B48;
  width: 90%;
  margin: 7px auto;
  color:white;
  font-size: large;
}

.ecard-game-timer{
  opacity: 90%;
  color:white;
  position: fixed;
  top:15%;
  right:10px;
  z-index: 999;
  /* background-color: rgba(0,0,0,.2); */
  padding:5px;
  background: rgb(0,0,0, .2);
background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 60%, rgba(0,0,0,1) 100%);
padding-left: 20px;
}



/* ProfileInfo */
.profileInfo {
  position: absolute;
  bottom: 10px;
  right: 1px;
  z-index:9999;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  background-color: black;
  white-space: nowrap;
  text-align: center;
  padding: 10px;
  color:white;
  border-radius: 10px;
  min-width: 100px;
}


/* LOADER */

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid #ffffff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 



/* 
  .copy-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .copy-label {
    padding: 10px;
    font-size: 18px;
    color: #111;
  }
  .copy-text {
    position: relative;
    padding: 10px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    display: flex;
  }
  .copy-text input.text {
    padding: 10px;
    font-size: 18px;
    color: #555;
    border: none;
    outline: none;
  }
  .copy-text button {
    padding: 10px;
    background: #5784f5;
    color: #fff;
    font-size: 18px;
    border: none;
    outline: none;
    border-radius: 10px;
    cursor: pointer;
  }
  
  .copy-text button:active {
    background: #809ce2;
  }
  .copy-text button:before {
    content: "Copied";
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
  }
  .copy-text button:after {
    content: "";
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
  }
  .copy-text.active button:before,
  .copy-text.active button:after {
    display: block;
  } */

  /* WINNER DIALOG */
  .winnerDialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(250, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 99999999999999;
    color: white;
  }

  .winnerDialog-win{
    background-color: rgba(0, 250, 154, 0.5);
  }

  .winnerDialog button {
    margin-top: 20px;
  }