.home-container {
    /* background: url("https://i.ibb.co/ssc0GqB/Default-pepe-the-frog-meme-gambling-at-the-ccasino-3.jpg") no-repeat center center fixed;
     */
    background: url("/public/homebg-3_.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    color:white;
}
.homeButtonContent{
    align-items: center;
    justify-content: center;
    display: flex;
    height: 10rem;
    /* height:100%; */
    margin: 0 auto;
    max-width: 150rem;
    width: 90%;
}

.home-footer{
    height: 20%;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin: 0; 
}
