.menu-main-div{
    background : url("../../../public/dots_orange.png") repeat-x,#f8e405;
    background-size: auto, auto;
    background-size: auto 100%;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow-y: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: -2;
}

.menu-header{
    height: 10%;
    width: 100%;
    background-color:black;
    color:white;
    font-size:xx-large;
    margin:0;
    padding: 1px;
}

.menu-header p {
    margin-left: 3%;
    font-size:xx-large;
}
/* .menu-items-menu p{
    padding-left: 3%;
    font-size:xx-large;
    margin-top : 4px;
    margin-bottom : 4px;
}

.menu-items-menu p:hover{
    background-color: black;
    color:white;
    padding-right: 50px;
    color:white;    
    cursor: pointer;
} */

.menu-items-menu p{
    padding-left: 3%;
    font-size:xx-large;
    margin-top : 4px;
    margin-bottom : 4px;
    width: 250px;
}

.menu-items-menu p:hover{
    background-color: black;
    /* display: inline; */
    padding-right: 50px;
    color:white;    
    cursor: pointer;
    width: 250px;
}

.menu-main-div img {
    width: 20%;
    margin-left: 35%;
    margin-top: 5%;
}

.shape-cards {
    background: url("../../../public/cards2.png") no-repeat;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    background-position: top;
    background-size: 100%;
    height: 100%;
    width: 40%;
    position: absolute;
    left: 40%;
    z-index: -1;
}

.logout-option {
    position: fixed;
    bottom: 20px;
    left: 0;
    padding-left: 3%;
    font-size: xx-large;
    width: 250px;
    color: #ff3333;
    cursor: pointer;
}

.logout-option:hover {
    background-color: #ff3333;
    color: white;
    padding-right: 50px;
}
