.connectButton-style{
    width: 300px;
	height: 40px;
	/* transform: skew(-20deg);
	background: rgb(9, 189, 212);
    border: 0; */
    clip-path : polygon(5% 0, 100% 0, 95% 100%, 0 100%);
    border: 0;
    background-image: url("../../../../public/button1-pattern.png");
    background-repeat: repeat-x;
    background-size: auto 120%;
}

.dots-blue-bg{
  background-image: url("../../../../public/dots_blue.png");
    background-repeat: repeat-x;
    background-size: auto 500%;
    background-position: center center;
}



/* .connectButton-style::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: skewX(20deg);
    background-image: url('../../../../public/button1-pattern.png');
    background-repeat: repeat-x;
    background-position: top left;
  } */

.connectButton-style .skew-fix{
    font-size: 20px;
  }