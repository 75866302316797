.ecard-lobby-content-div{
    /* transform: rotate(180deg); */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 15%;
}

.ecard-lobby-content-div::before {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url("../../../../public/dots_orange.png") repeat-x #f8e405;
    transform: rotate(180deg);
    z-index: -1;
    height: 100%;
    width: 100%;
  }

.ecard-lobby-header-div{
    height: 200px;
    width:100%;
    background: url("../../../../public/lobby-bg.png");
    background-size: cover;
    flex-direction: row;
    /* background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center; */
}  

.ecard-lobby-header-div button{
    margin: 0px 20px;
}



/* SINGLE ROOM */
.ecard-room-main{
    background: url("../../../../public/dots_blue.png") repeat-x ;
    margin: 10px 0;
    height: 70px;
    width:570px;
    min-width: 200px; 
    /* TODO */
    /* max-width: 500px;
    min-width: 200px; */
    display: flex;
    justify-content: center;
  align-items: center;
  flex-direction: row;
}
.ecard-room-main:hover {
    box-shadow: rgba(0, 0, 0, 0.4) 5px 5px, rgba(0, 0, 0, 0.3) 10px 10px, rgba(0, 0, 0, 0.2) 15px 15px, rgba(0, 0, 0, 0.1) 20px 20px, rgba(0, 0, 0, 0.05) 25px 25px;
    transition: box-shadow 0.2s ease-in-out;
  }

.ecard-room-border{
    border: 2px solid white;
    width:98%;
    height:90%;
    z-index: 3;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.ecard-room-border button {
    width: 50px;
    height: 40px;
}

.ecard-room-border div p{
    margin: 0;
    padding: 0;
    background-color: black;
    padding: 0 10px;
    margin: 2px 0px;
    width: 100%;
}

.ecard-room-border div p:nth-child(2){
    background-color: rgb(40, 50, 82);
}


@media screen and (max-width:520px) {
    .ecard-room-main{
        /* width: 400px; */
        height: 100%;
        width: 80%;
        padding: 5px 0;
    }

    .ecard-room-main div{
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        justify-content: center;
        align-items: center;
    }
    .ecard-room-border div p{
        white-space: wrap;
        width: 70%;
        position: relative;
        text-overflow: ellipsis;
        overflow: clip;
    }
}

/* @media screen and (max-width:420px) {
    .ecard-room-main{
        width: 300px;
    }
}

@media screen and (max-width:320px) {
    .ecard-room-main{
        width: 250px;
    }
} */

.cancelBtn {
    font-size: large;
    text-transform: uppercase;
    border-bottom: 2px solid red;
    padding: 0px 3px;
    font-weight: bold;
}

.joinBtn {
    font-size: large;
    text-transform: uppercase;
    border-bottom: 2px solid rgb(43, 255, 0);
    padding: 0px 3px;
    font-weight: bold;
}

.bot-wager-dialog {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.bot-wager-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 20px 0;
}

.bot-wager-options button {
  min-width: 200px;
  padding: 10px 20px;
}